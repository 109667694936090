const questions = [
  {
    questionText: "Зачем тебе истина? ",
    answerOptions: [
      {
        answerText: "Все знают, и я хочу.",
        category: "cat1",
      },
      {
        answerText: "Хочу впечатлить знаниями кота.",
        category: "cat2",
      },
      {
        answerText: "42.",
        category: "cat3",
      },
      {
        answerText: "Шеф сказал, что это важно.",
        category: "cat4",
      },
    ],
    image: "images/01.webp",
  },

  {
    questionText: "В чем твоя сила?",
    answerOptions: [
      {
        answerText: "В бицухе, я три раза в неделю в зал хожу.",
        category: "cat1",
      },
      {
        answerText: "Живу в одном доме с Максимом Викторовичем.",
        category: "cat3",
      },
      {
        answerText: "Красиво закрываю задачи.",
        category: "cat2",
      },
      {
        answerText: "Что есть сила? В чем измерять?",
        category: "cat4",
      },
    ],
    image: "images/02.webp",
  },

  {
    questionText: "Что ты несешь в этот мир?",
    answerOptions: [
      {
        answerText: "В основном чушь.",
        category: "cat3",
      },
      {
        answerText: "Счастье и красоту.",
        category: "cat2",
      },
      {
        answerText: "Закон и порядок.",
        category: "cat1",
      },
      {
        answerText: "Эстетику носков с сандалиями.",
        category: "cat4",
      },
    ],
    image: "images/03.webp",
  },

  {
    questionText: "Что первее — яйцо или курица?",
    answerOptions: [
      {
        answerText: "Не знаю, еще не был в столовой.",
        category: "cat3",
      },
      {
        answerText: "Петух, который хотел немного внимания от курицы.",
        category: "cat2",
      },
      {
        answerText: "Кто первым записывался.",
        category: "cat1",
      },
      {
        answerText: "Динозавры.",
        category: "cat4",
      },
    ],
    image: "images/04.webp",
  },

  {
    questionText: "Что делать с пустотой внутри?",
    answerOptions: [
      {
        answerText: "Положить туда шоколад.",
        category: "cat3",
      },

      {
        answerText: "Наполнить бабочками.",
        category: "cat2",
      },
      {
        answerText: "Устроить ее на работу, пусть платит за аренду.",
        category: "cat1",
      },
      {
        answerText: "Что есть пустота? Внутри чего?",
        category: "cat4",
      },
    ],
    image: "images/05.webp",
  },

  {
    questionText: "Как стать успешным?",
    answerOptions: [
      {
        answerText: "Считать каждую ошибку опытом.",
        category: "cat4",
      },
      {
        answerText: "Не скажу. Это коммерческий секретик.",
        category: "cat2",
      },
      {
        answerText: "Ставить реалистичные цели. Например, покушать.",
        category: "cat3",
      },
      {
        answerText: "Закрыть все заявки в «Хелпе».",
        category: "cat1",
      },
    ],
    image: "images/06.webp",
  },

  {
    questionText: "Человек всесилен, пока…",
    answerOptions: [
      {
        answerText: "…нет штрафов за опоздания.",
        category: "cat3",
      },
      {
        answerText: "…работает кофемашина.",
        category: "cat1",
      },
      {
        answerText: "…уверен, что его микрофон в «Зуме» выключен. ",
        category: "cat2",
      },
      {
        answerText:
          "…удается избежать часовой встречи по вопросу на пять минуток.",
        category: "cat4",
      },
    ],
    image: "images/07.webp",
  },

  {
    questionText: "В чем твое счастье? ",
    answerOptions: [
      {
        answerText: "В волшебном слове «согласовано».",
        category: "cat1",
      },
      {
        answerText: "В пузырьках шампанского на новогоднем «Намонасе».",
        category: "cat2",
      },
      {
        answerText: "В пятничке!",
        category: "cat3",
      },
      {
        answerText: "Не скажу. Я под NDA.",
        category: "cat4",
      },
    ],
    image: "images/08.webp",
  },
];

export default questions;
